<template>
    <header :class="[$style.header, classLayout]">
        <div :class="$style.header__sp">
            <Logo
                name="header"
            />
            <template v-if="name === 'dashboard'">
                <Hamburger
                    :name="name"
                />
            </template>
        </div>
        <template v-if="name === 'dashboard'">
            <nav>
                <ul>
                    <li>
                        <router-link :to="{ name: `${name}_signout` }" type="button" tag="button">
                            サインアウト
                        </router-link>
                    </li>
                </ul>
            </nav>
        </template>
    </header>
</template>

<script>
    "use strict";

    import Hamburger from "@/components/TheHamburger";
    import Logo from "@/components/Logo.vue";

    import string from "@/mixins/string.js";

    export default {
        "components": {
            Hamburger,
            Logo,
        },
        "mixins": [
            string,
        ],
        "props": {
            "name": {
                "type": String,
                "required": true,
            },
        },
        "computed": {
            // layouts別のclassを追加
            classLayout() {
                const adjName = this.$_initialUpperCase(this.name);
                return this.$style[`is${adjName}`];
            },
        },
    };
</script>

<style lang="scss" module>
    .isDashboard {
        background: #fff;
        color: #4c4c4c;
        padding: 8px;
        position: sticky;
        top: 0;
        width: 100%;
        z-index: 999;
        > nav {
            @include sp {
                display: none;
            }
            > ul {
                display: flex;
                justify-content: flex-end;
                > li {
                    margin-left: 1.5em;
                }
            }
        }
        .header {
            &__sp {
                display: none;
                @include sp {
                    display: block;
                }
            }
        }
    }

    .header {
        @include sp {
            background: #222;
            border-top: 4px solid $theme-color;
            padding: 0 56px;
        }
    }
</style>
