<template>
    <div>
        <div :id="button" class="hamburgerButton" :class="activeToggle" @click="toggleMenu()">
            <div class="hamburgerButton__inner">
                <div class="hamburgerButton__inner__item" :class="activeToggle"></div>
                <div class="hamburgerButton__inner__item" :class="activeToggle"></div>
                <div class="hamburgerButton__inner__item" :class="activeToggle"></div>
            </div>
        </div>
        <div class="hamburgerMenu" :class="activeToggle" :style="style.menu">
            <div class="hamburgerMenu__inner" :class="activeToggle">
                <!-- <div class="hamburgerHeader">
                    <div class="hamburgerHeader__title">
                        {{ title }}
                    </div>
                </div> -->
                <nav class="hamburger">
                    <ul class="hamburger__list">
                        <li v-for="(menu, key) in menuData" :key="key" class="hamburger__list__item">
                            <router-link class="hamburger__list__anchor" :active-class="activeClass" :to="{ name: menu.link }" @click.native="toggleMenu()">
                                {{ menu.text }}
                            </router-link>
                            <template v-if="menu.list">
                                <span class="hamburgerOpenerIcon" :class="openerActiveToggle(key)" @click="opener(key)"></span>
                                <ul :id="key" class="hamburger__list__list" :style="style[key]">
                                    <li v-for="(menu2, key2) in menu.list" :key="key2" class="hamburger__list__list__item">
                                        <router-link class="hamburger__list__anchor" :active-class="activeClass" :to="{ name: menu2.link }" @click.native="toggleMenu()">
                                            {{ menu2.text }}
                                        </router-link>
                                    </li>
                                </ul>
                            </template>
                        </li>
                    </ul>
                </nav>
                <div class="hamburgerFoter">
                    <router-link class="hamburgerFoter__logout" :to="{ name: `${name}_signout` }" :tag="'button'" @click.native="toggleMenu()">
                        サインアウト
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    "use strict";

    const htmlTag = document.getElementsByTagName("html")[0];

    const menu = {
        "orderList": {
            "link": "order_list",
            "text": "注文管理",
        },
        "itemList": {
            "link": "item_list",
            "text": "商品管理",
        },
    };

    // menu.dailyReport.list = menu;

    export default {
        "props": {
            "name": {
                "type": String,
                "required": true,
            },
        },
        data() {
            return {
                "activeClass": "isActive",
                "button": "js-hamburger-button",
                "menu": false,
                "menuData": menu,
                "openerData": {
                    // "dailyReport": false,
                },
                "openerToggle": {},
                "style": {
                    "menu": {
                        "height": 0,
                    },
                },
                "title": "MENU",
            };
        },
        "computed": {
            activeToggle() {
                if (this.menu) {
                    return this.activeClass;
                }
            },
        },
        created() {
            for (let key in this.openerData) {
                this.$set(this.openerToggle, key, {
                    "show": true,
                });
                this.$set(this.style, key, {
                    "height": "auto",
                });
            }
        },
        mounted() {
            for (let key in this.openerData) {
                this.openerToggle[key].height = document.getElementById(key).scrollHeight;
                this.openerToggle[key].show = !this.openerData[key];
                this.opener(key);
            }
        },
        "methods": {
            menuOpen() {
                const headerBorderTop = 4;
                const windowHeight = window.innerHeight;
                const navPosition = document.getElementById(this.button).clientHeight;
                const navHeight = windowHeight - (navPosition + headerBorderTop);
                this.style.menu.height = `${navHeight}px`;
            },
            menuClose() {
                this.style.menu.height = 0;
            },
            toggleMenu() {
                this.menu = !this.menu;
                if (this.menu) {
                    htmlTag.style.overflow = "hidden";
                    this.menuOpen();
                    this.resizeMenu();
                } else {
                    htmlTag.style.overflow = "visible";
                    this.menuClose();
                }
            },
            resizeMenu() {
                let timer = 0;
                window.onresize = () => {
                    if (timer > 0) {
                        clearTimeout(timer);
                    }
                    timer = setTimeout(() => {
                        if (this.menu) {
                            this.menuOpen();
                        }
                    }, 100);
                };
            },
            opener(key) {
                this.openerToggle[key].show = !this.openerToggle[key].show;
                if (this.openerToggle[key].show) {
                    this.style[key].height = `${this.openerToggle[key].height}px`;
                } else {
                    this.style[key].height = 0;
                }
            },
            openerActiveToggle(key) {
                if (this.openerToggle[key].show) {
                    return this.activeClass;
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    .hamburgerButton {
        background: $hamburger-button-background;
        cursor: pointer;
        display: none;
        height: 56px;
        position: absolute;
        right: 0;
        top: 0;
        width: 56px;
        z-index: 999;
        @include sp {
            display: block;
        }
        &__inner {
            > *:first-child {
                margin-top: 0;
            }
            > *:last-child {
                margin-bottom: 0;
            }
            &__item {
                background: $hamburger-button-item-background;
                display: block;
                height: 3px;
                left: 0;
                margin: auto;
                position: absolute;
                right: 0;
                transition: .35s ease-in-out;
                width: 32px;
                &:nth-child(1) {
                    top: 16px;
                }
                &:nth-child(2) {
                    top: 26px;
                }
                &:nth-child(3) {
                    top: 36px;
                }
                &.isActive {
                    &:nth-child(1) {
                        top: 28px;
                        -webkit-transform: rotate(-45deg);
                                transform: rotate(-45deg);
                    }
                    &:nth-child(2) {
                        opacity: 0;
                        transition: none;
                    }
                    &:nth-child(3) {
                        top: 28px;
                        -webkit-transform: rotate(45deg);
                                transform: rotate(45deg);
                    }
                }
            }
        }
    }

    .hamburgerMenu {
        background: $hamburger-menu-background;
        display: none;
        height: 0;
        left: 0;
        overflow: hidden;
        position: fixed;
        top: 60px;
        transition: .5s cubic-bezier(.6, 0, .4, 0);
        width: 100%;
        z-index: 999;
        @include sp {
            display: block;
        }
        &.isActive {
            height: 100%;
        }
        &__inner {
            opacity: 0;
            transition: .5s cubic-bezier(.6, 0, .4, 0);
            > *:first-child {
                margin-top: 0;
            }
            > *:last-child {
                margin-bottom: 0;
            }
            &.isActive {
                opacity: 1;
            }
        }
    }

    .hamburgerHeader {
        background: $hamburger-header-background;
        color: $hamburger-header-text-color;
        &__title {
            font-size: 24px;
            padding: 16px 8px;
            text-align: center;
        }
    }

    .hamburgerFoter {
        border-bottom: 2px solid $theme-color;
        bottom: 0;
        color: $hamburger-footer-text-color;
        left: 0;
        padding: 15px 20px;
        position: absolute;
        width: 100%;
        &__logout {
            @include button ($hamburger-footer-background);
            padding: 16px;
            width: 100%;
        }
    }

    .hamburger {
        overflow-y: scroll;
        padding: 15px 20px;
        -webkit-overflow-scrolling: touch;
        &__list {
            &__item {
                border-top: $hamburger-menu-anchor-border;
                font-size: 16px;
                position: relative;
                &:first-child {
                    border-top: none;
                }
            }
            &__anchor {
                color: rgba($color: $hamburger-menu-anchor-color, $alpha: .5);
                display: block;
                font-size: 21px;
                padding: 16px 8px;
                &.isActive {
                    color: $hamburger-menu-anchor-color;
                }
            }
            &__list {
                overflow: hidden;
                transition: .5s cubic-bezier(.6, 0, .4, 0);
                &__item {
                    border-top: $hamburger-menu-anchor-border;
                    font-size: 16px;
                    padding: 0 0 0 2.4em;
                }
            }
        }
    }

    .hamburgerOpenerIcon {
        cursor: pointer;
        height: 48px;
        position: absolute;
        right: 0;
        top: 0;
        width: 48px;
        z-index: 999;
        &::before {
            background: rgba($color: $hamburger-menu-anchor-color, $alpha: .5);
            bottom: 0;
            content: "";
            height: 2px;
            left: 0;
            margin: auto;
            position: absolute;
            right: 0;
            top: 0;
            transition: .2s all ease-in-out;
            width: 24px;
        }
        &::after {
            background: rgba($color: $hamburger-menu-anchor-color, $alpha: .5);
            bottom: 0;
            content: "";
            height: 24px;
            left: 0;
            margin: auto;
            position: absolute;
            right: 0;
            top: 0;
            transition: .2s all ease-in-out;
            width: 2px;
        }
        &.isActive {
            &::before {
                -webkit-transform: rotate(180deg);
                        transform: rotate(180deg);
                transition: .2s all ease-in-out;
            }
            &::after {
                -webkit-transform: rotate(90deg);
                        transform: rotate(90deg);
                transition: .2s all ease-in-out;
            }
        }
    }
</style>
